@forward "./main-scss/";
@forward "./listing/listing-common";
@forward "./partials/pagination";
@use "./config/" as *;

.waf-listing {
    @extend %p-4;
    .waf-footer,.filter-section,.loadmore-wrap {
        @extend %d-none;
    }
    .waf-body {
        @extend %half-radius;
        @extend %p-4;
        @extend %pure-white-900-bg;
    }
    .waf-head {
        @extend %pb-8;
        .title {
            @extend %font-20-pb;
        }
        .head-tab {
            @extend %d-none;
        }
    }
    .first-list {
        .article-title {
            --_listing-title: 20;
        }
        .article-meta {
            @extend %pt-6;
        }
    }
    .article {
        &-description {
            @extend %d-none;
        }
        &-content {
            padding-inline: var(--space-0);
            padding-block: var(--space-4);
        }
        &-title {
            @extend %neutral-800;
        }
        &-meta {
            .meta {
                font-size: 1rem;
                font-weight: 500;
                @extend %neutral-500;
                &-author {
                    line-height: 1.6rem;
                }
                &-category {
                    top: var(--space-4);
                }
            }
        }
    }
    .second-list {
        @include listing-card(horizontal);
        .article {
            &-item {
                border-radius: 0;
                @extend %mb-6;
            }
            &-content {
                position: relative;
                @extend %pl-3;
                @extend %pt-3;
                @extend %pb-0;
            }
            &-title {
                margin-top: var(--space-2);
            }
            &-meta {
                margin-bottom: 0;
                .meta-category {
                    top: 0;
                    left: var(--space-3);
                }
            }
        }
    }
    &.webstory-listing {
        .first-list {
            .article-title {
                margin-top: 0;
                line-height: 1.6;
                height: 3.6rem;
            }
        }
        .article-meta {
            padding-top: 0;
        }
    }
}
.webstory-listing {
    padding: var(--space-4);
    .waf-body {
        background: transparent;
        padding: 0;
    }
    .first-list {
        @include card-count(2,var(--space-4),true);
    }
    .article-item {
        &::before {
            content: '';
            z-index: var(--z-overlay);
            pointer-events: none;
            background: linear-gradient(0deg, var(--secondary-800) 0%, clr(secondary-800,0) 61.75%);;
            @extend %w-100;
            @extend %h-100;
            @extend %pos-bl;
        }
    }
    .article-counter {
        width: calc(100% - var(--space-6));
        @include position(var(--space-3),var(--space-3),null,var(--space-3));
        @extend %flex;
        @extend %gap-1;
        .count-dash {
            height: .2rem;
            flex: 1;
            @extend %half-radius;
            @extend %neutral-100-bg;
        }
    }
    .article-content {
        position: absolute;
        bottom: 0;
        padding: 0 var(--space-3) var(--space-3);
    }
    .article-title {
        font-size: 1.2rem;
        @extend %pure-white-900;
    }
    .article-meta {
        margin-top: var(--space-5);
        .meta-category {
            @extend %d-none;
        }
        .meta {
            font-size: 1rem;
            @extend %pure-white-900;
        }
    }
}
@include mq(col-md) {
    .waf-listing {
        padding: var(--space-6) 0;
        .waf-head {
            .title {
                font-size: 2.4rem;
            }
        }
        .article-meta {
            .meta {
                font-size: 1.2rem;
                line-height: 1.8rem;
            }
        }
        .first-list {
            display: flex;
            flex-wrap: wrap;
            column-gap: var(--space-4);
            row-gap: 0;
            .article-title {
                --_listing-title: 24;
                line-height: 30px;
                height: auto;
                margin-top: var(--space-6);
            }
            .article-item {
                width: calc(50% - var(--space-2));
            }
        }
        .second-list {
            @include listing-card(vertical);
            .article {
                &-content {
                    padding-left: 0;
                    padding-block: var(--space-4);
                }
                &-item {
                    margin-bottom: 0;
                }
                &-title {
                    margin-top: var(--space-7);
                    margin-bottom: var(--space-4);
                }
                &-meta {
                    .meta-category {
                        left: 0;
                        top: var(--space-4);
                    }
                }
            }
        }
        &.webstory-listing {
            .first-list {
                @include card-count(4,var(--space-4));
            }
        }
    }
}
@include mq(col-lg) {
    .waf-listing {
        &.webstory-listing {
            .first-list {
                @include card-count(5,var(--space-4));
            }
            .article-meta {
                .meta {
                    font-size: 1rem;
                }
            }
        }
    }
    .webstory-listing {
        .article-title  {
            font-size: 1.2rem;
        }
    }
}